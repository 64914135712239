import {
  prepareUserUrl,
  endpoint,
  prepareContactsUrl,
  prepareTasksUrl,
  prepareChatsUrl,
  prepareDonationsUrl,
  preparePostsUrl,
  prepareCategoriesUrl,
} from './config';

export const backendUrls = {
  users: {
    REGISTER: prepareUserUrl('register'),
    LOGIN: prepareUserUrl('login'),
    VERIFY: prepareUserUrl('verify-account'),
    RESENDVERIFICATIONCODE: prepareUserUrl('resend-verification-code'),
    FORGOTPASSWORD: prepareUserUrl('forgot-password'),
    RESETPASSWORD: prepareUserUrl('reset-password'),
    REFRESHTOKEN: prepareUserUrl('refresh-token'),
    CHANGEPASSWORD: prepareUserUrl('change-password'),
    ABOUT_US: prepareUserUrl('about-us')
  },
  projects: {
    GETPROJECTS: `${endpoint}/projects`,
    GETALLPROJECTS: `${endpoint}/projects/all`,
    GETPROJECTDETAILS: (id: string) => `${endpoint}/projects/${id}`,
    ADDATTACHMENT: (id: string) => `${endpoint}/projects/add-attachments/${id}`,
    DELETEPROJECTATTACHMENT: `${endpoint}/projects/remove-attachment`,
    EDITPROJECT: `${endpoint}/projects/edit-project`,
    ADDPROJECT: `${endpoint}/projects/add-project`,
    ADD_POST: `${endpoint}/posts/add-post`,
    DELETEPROJECT: `${endpoint}/projects`,
    TOGGLEVISIBILITY: `${endpoint}/projects/toggle-project-visibility`,
    UPDATEPROJECTIMAGE: `${endpoint}/projects/update-project-image`,
    REMOVEPROJECTMANAGER: `${endpoint}/projects/remove-manager`,
    ADDPROJECTMANAGER: `${endpoint}/projects/add-manager`,
    ADDPROJECTCONTRIBUTORS: `${endpoint}/projects/add-contributors`,
    REMOVEPROJECTCONTRIBUTOR: `${endpoint}/projects/remove-contributor`,
  },
  members: {
    GETMEMBERS: prepareUserUrl('get-members'),
    GETUSERS: prepareUserUrl('get-users'),
    APPROVEMEMBER: prepareUserUrl('approve-member'),
    ADDPROFILE: prepareUserUrl('upload-profile-image'),
    UPDATEPROFILE: prepareUserUrl('update-profile'),
    UPDATEGENERALSETTINGS: prepareUserUrl('update-general-settings'),
    UPDATENOTIFICATIONSETTINGS: prepareUserUrl('update-notification-settings'),
    GETPROFILE: prepareUserUrl('profile'),
  },
  contact: {
    CREATEFORM: prepareContactsUrl('create-form'),
    SUBSCRIBE: prepareContactsUrl('subscribe'),
    GETPARTNERS: prepareContactsUrl('get-partners'),
  },
  tasks: {
    ADDSECTION: prepareTasksUrl('add-section'),
    DELETESECTION: (id: string) => prepareTasksUrl(`delete-section/${id}`),
    DELETESUBTASK: prepareTasksUrl('remove-sub-task'),
    EDITSUBTASK: prepareTasksUrl('edit-sub-task'),
    CHANGESECTION: prepareTasksUrl('change-section'),
    ADDTASK: prepareTasksUrl('add-task'),
    EDITTASK: (id: string) => prepareTasksUrl(`edit-task/${id}`),
    DELETETASK: (id: string) => prepareTasksUrl(`delete-task/${id}`),
    DELETETASKFILE: prepareTasksUrl('delete-attachment'),
    DELETEASSIGNEE: prepareTasksUrl('remove-task-assignee'),
    GETTASKDETAILS: (id: string) => prepareTasksUrl(`get-task/${id}`),
    TOGGLESUBTASKSTATUS: (id: string) => prepareTasksUrl(`sub-task/toggle-completed/${id}`),
  },
  chats: {
    GETALLMESSAGES: prepareChatsUrl(''),
    GETUSERSMESSAGES: prepareChatsUrl('user-messages'),
    DELETEMESSAGE: prepareChatsUrl('delete-messages'),
  },
  donations: {
    CREATEDONATION: prepareDonationsUrl(''),
    GETDONATIONS: prepareDonationsUrl(''),
    GETTRANSFERS: prepareDonationsUrl('transfers'),
  },
  posts: {
    ADD_POST: preparePostsUrl('add-post'),
    GET_POSTS: preparePostsUrl('all'),
    GET_POST_DETAILS: (id: string) => preparePostsUrl(`${id}`),
    UPDATE_POST: (id: string) => preparePostsUrl(`${id}`),
    DELETE_POST: (id: string) => preparePostsUrl(`${id}`),
  },
  categories: {
    ADD_CATEGORY: prepareCategoriesUrl('add-category'),
    GET_CATEGORIES: prepareCategoriesUrl(''),
    GET_CATEGORY_POSTS: (id: string) => prepareCategoriesUrl(`${id}`),
    UPDATE_CATEGORY: (id: string) => prepareCategoriesUrl(`${id}`),
    DELETE_CATEGORY: (id: string) => prepareCategoriesUrl(`${id}`),
  },
};
